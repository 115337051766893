import React from "react";
import {
  Layout,
  Button,
  Typography,
  Card,
  Form,
  Input,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { api } from "../api";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { rolesNavigation } from '../hook/roleNavigation'

const { Title } = Typography;
const { Content } = Layout;

export default function Verification() {
  const [form] = useForm();
  const Naviagte = useNavigate()
  let {id} = useParams();


  const loginUser = (value) =>{
    value = {...value, user_id : id}
    axios.post(`${api}/users/verifycode.php`, JSON.stringify(value))
    .then(res =>{ 
        if(res.data.status){
            message.success(res.data.message)
            localStorage.setItem('encreden', JSON.stringify(res.data.data))
            form.resetFields();
            rolesNavigation(res.data.data, Naviagte)
        }
        else{
            message.error(res.data.message)
        }
    })
    .catch(e => message.error('Something Went Wrong'))
}

    return (
      <>
        <div className="layout-default ant-layout layout-sign-up ">
          <Content className="p-0">
            <div className="sign-up-header">
              <div className="content">
                <Title>Script Top Solution</Title>
              </div>
            </div>

            <Card
              className="card-signup header-solid h-full ant-card pt-0 verification-form"
              bordered="false"
            >
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={loginUser}
                className="row-col"
                form={form}
              >
                <h3>Enter Your Verification Code</h3>
                <h6>(Has Been Sent To Admin)</h6>
                <Form.Item
                  name="code"
                  rules={[
                    { required: true, message: "Enter Your Verification Code" },
                  ]}
                >
                  <Input type="number" placeholder="Code Eg:123456" />
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    Verify
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Content>
        </div>
      </>
    );
}
