
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home.jsx";
import SignUp from "./pages/SignUp.jsx";
import SignIn from "./pages/SignIn.jsx";
import Main from "./components/layout/Main.jsx";
import Brands from './pages/Brand';
import Orders from "./pages/order.jsx";
import Payment from "./pages/payment.jsx";
import AddBrand from "./pages/addBrand.jsx";
import AddLead from "./pages/addLead.jsx";
import Leads from "./pages/lead.jsx";
import CreateOrder from "./pages/createOrder.jsx";
import OrderPayment from "./pages/order_payment";
import Employee from "./pages/users.jsx";
import Verification from "./pages/verification.jsx";
import EditEmployee from './pages/editEmployee';
import ViewOrder from "./pages/viewOrder.jsx";
import { AdminProtection, SaleProtection, ProtectedRoute } from "./hook/useAuth.jsx";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/sign-in" exact element={<SignIn />} />
        <Route path="/verification/:id" element={<Verification />} />
        <Route exact path="/" element={
          <ProtectedRoute>
            <Main />
          </ProtectedRoute >
        }>
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/brands" element={
            <AdminProtection>
              <Brands />
            </AdminProtection>
          } />

          <Route path={`orders`}>
            <Route path='' element={<Orders />} />
            <Route path=':id' element={<ViewOrder />} />
          </Route>
          
          <Route exact path="/Payment" element={
            <SaleProtection>
              <Payment />
            </SaleProtection>
          } />
          <Route exact path="/add_brand" element={
            <AdminProtection>
              <AddBrand />
            </AdminProtection>
          } />
          <Route exact path="/add_lead" element={
            <SaleProtection>
              <AddLead />
            </SaleProtection>
          } />
          
          <Route exact path="/leads" element={
            <SaleProtection>
              <Leads />
            </SaleProtection>
          } />
          <Route exact path="/sales" element={
            <SaleProtection>
              <Payment />
            </SaleProtection>
          } />

          <Route path="/sign-up" exact element={
            <AdminProtection>
              <SignUp />
            </AdminProtection>
          } />
          <Route path="/edit-employee" exact element={
            <AdminProtection>
              <EditEmployee />
            </AdminProtection>
          }>

          </Route>
          <Route path="/employee" exact element={
            <AdminProtection>
              <Employee />
            </AdminProtection>
          } />
          <Route exact path="/create_order/" element={
            <SaleProtection>
              <CreateOrder />
            </SaleProtection>
          } />
          <Route exact path="/generate_order_link/:id" element={
            <SaleProtection>
              <OrderPayment />
            </SaleProtection>
          } />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
