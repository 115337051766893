import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSingleOrder } from "../store/action/order";
import UseIsMounted from "../hook/mounted";
import { message } from "antd";

function ViewOrder() {
    const { id } = useParams();
    const isMounted = UseIsMounted();
    const [data, setData] = useState(null);

    useEffect(() => {
        getSingleOrder(id)
            .then(res => isMounted.current && setData(res.data[0]))
            .catch(() => message.error("Something went wrong"));
    }, [id, isMounted]);

    console.log(data)

    if (!data) {
        return <div className="text-center">Loading Order Details</div>;
    }

    return (
        <div className="invoice-box">
            <table cellPadding="0" cellSpacing="0">
                <tr className="top">
                    <td colSpan="2">
                        <table>
                            <tr>
                                <td className="title">
                                    <h2>Order Information</h2>
                                </td>
                                <td>
                                    <strong>Order ID:</strong> {data.order_id}<br />
                                    <strong>Due Date:</strong> {new Date(data.writer_deadline).toDateString()}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr className="information">
                    <td colSpan="2">
                        <table>
                            <tr>
                                <td>
                                    <strong>Customer Details:</strong><br />
                                    Level of Studies: {data.level_of_studies}<br />
                                    Subject Area: {data.subject_area}<br />
                                    Paper Topic: {data.paper_topic}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr className="heading">
                    <td>Order Details</td>
                    <td></td>
                </tr>
                <tr className="details">
                    <td>
                        <strong>Type of Paper:</strong> {data.type_of_paper}<br />
                        <strong>Word Count:</strong> {data.word_count}<br />
                        <strong>Language Style:</strong> {data.lang_style}<br />
                        <strong>Referencing Style:</strong> {data.refrence_style}<br />
                        <strong>Number of References:</strong> {data.no_of_refrences}
                    </td>
                </tr>
                {/* <tr className="heading">
                    <td>Financial Details</td>
                    <td></td>
                </tr>
                <tr className="details">
                    <td>
                        <strong>Amount:</strong> {data.amount_currency} {data.amount}<br />
                        <strong>Currency:</strong> {data.amount_currency} (British Pound)<br />
                        <strong>Default Currency Amount:</strong> {data.default_currency_amount}
                    </td>
                </tr> */}
                {/* <tr className="heading">
                    <td>Assignment Details</td>
                    <td></td>
                </tr>
                <tr className="details">
                    <td>
                        <strong>Assigned Writer:</strong> {data.writer_name}<br />
                        <strong>Writer Deadline:</strong> {new Date(data.writer_deadline).toLocaleString()}<br />
                    </td>
                </tr> */}
                <tr className="heading">
                    <td>Additional Comments</td>
                    <td></td>
                </tr>
                <tr className="details">
                    <td>
                        {data.comment}
                    </td>
                </tr>
            </table>
        </div>
    );
}

export default ViewOrder;
