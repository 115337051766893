import { Form } from 'antd';
import { Col, Row } from 'antd';
import { useState } from 'react';

//component
import InputIcon from '../forms/inputIcon';
import FormButton from '../forms/button';
import CustomInputNumber from '../forms/inputNumber';
import { useNavigate } from 'react-router-dom';
import AppLoader from '../loader'
import axios from 'axios';
import { api } from '../../api';
import { message } from 'antd';




export default function AddLeadForm() {
    const Navigate = useNavigate();
    const [form] = Form.useForm();
    const [blockSubmit, setBlockSubmit] = useState(false)

    // loginSubmit
    const onSubmit = async (values) => {
        setBlockSubmit(true)
        axios.post(`${api}/leads/index.php`, JSON.stringify(values))
        .then((res)=>{
            if(res.data.status){
                message.success(res && res.data.message)
                setTimeout(() => {
                    form.resetFields();
                    Navigate('/leads')
                }, 1500);
              
            }
            else{
                setBlockSubmit(false)
                message.error(res && res.data.message)
            }
        })
    };



    return (
        <div className='create-form-inner'>
            <h2>Add Lead</h2>
            {blockSubmit && <AppLoader size="small" />}
                <Form
                    form={form}
                    name="lead-form"
                    className='lead-form'
                    initialValues={{}}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={24}  className="input-div">
                            <InputIcon name='name' message='Enter customer name' type="string" />     
                        </Col> 
                        <Col span={24} md={24} className="input-div">
                            <InputIcon name='email' message='Enter customer email' type="string" />     
                        </Col>   
                        <Col span={24} md={24} className="input-div">
                            <CustomInputNumber name='phone'  message='Enter customer phone' type="string" min={11} />      
                        </Col>  
                        <Col span={24} className="input-div d-flex justify-content-center">
                            <FormButton value="Add Lead" btntype="submit"  disabled={blockSubmit} />
                        </Col>
                    </Row>
                </Form>
        </div>
    );
};

