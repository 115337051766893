import React from "react";
import {
  Layout,
  Button,
  Card,
  Form,
  Input,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { api } from "../api";
import axios from "axios";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { rolesNavigation } from '../hook/roleNavigation'
import Logo from '../assets/images/logo.png'

const { Content } = Layout;

export default function Signin() {
  const [form] = useForm();
  const Navigate = useNavigate()
  const loginUser = (value) => {
    axios.post(`${api}/users/signin.php`, JSON.stringify(value))
      .then(res => {
        if (res.data.status && res.data.message === 'redirect') {
          var user_id = res.data.data
          Navigate('/verification/' + user_id)
        }
        else if (res.data.status && res.data.message.includes('Successful')) {
          message.success(res.data.message)
          localStorage.setItem('encreden', JSON.stringify(res.data.data))
          form.resetFields();
          rolesNavigation(res.data.data, Navigate)
        }
        else {
          message.error(res.data.message)
        }
      })
      .catch(e => message.error('Something Went Wrong'))
  }

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up ">
        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content">
              <div className="brand text-center logo-cont">
                <img src={Logo} alt="logo" />
              </div>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            bordered="false"
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={loginUser}
              className="row-col"
              form={form}
            >
              <h5 className="heading">Sign In</h5>
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your username! or email" },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password placeholder="Password" type="password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: "100%", marginTop: "20px" }}
                  type="primary"
                  htmlType="submit"
                >
                  SIGN IN
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Content>
      </div>
    </>
  );
}
