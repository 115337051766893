import { useState, useEffect, useRef } from "react";

import {
    Card,
    Col,
    Row,
    Typography,
    Menu,
    Button,
    Timeline,
    Form,
    message
} from "antd";

import StyleTable from "../components/table";
import StyleDropdown from "../components/forms/dropdown";
import { ExportOutlined, FormOutlined,CloseCircleFilled } from "@ant-design/icons";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import CustomRangeDatePicker from "../components/forms/rangeDatepicker";
import CustomDatePicker from "../components/forms/datePicker";
import FormButton from "../components/forms/button";
import InputIcon from "../components/forms/inputIcon";
import StyleModal from "../components/modal";
import StyleDrawer from "../components/drawer";
import { Link } from "react-router-dom";
import { all_data, updateOrders, monthData, copyCompleteOrder } from "../store/action/order";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import CustomSelectBox from "../components/forms/selectBox";
import { Checkbox } from 'antd';
import axios from "axios";
import { api } from "../api";
import { LoginInfo } from "../hook/lognInfo";
import { SearchOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import Highlighter from 'react-highlight-words'



function Orders() {
    const { Title, Text } = Typography;
    const dispatch = useDispatch()
    const [startModal, setStartModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [assignWriter, setAssignWriter] = useState(false)
    const [monthDataAllow, setMonthDataAllow] = useState(false)
    const [assignOutSourse, setAssignOutSource] = useState(false)

    const [allOrCheck, setAllOrCheck] = useState(JSON.parse(localStorage.getItem('allOrderAllow')))


    const [writerList, setWriterList] = useState([])

    //logs list
    const [timelineList, setTimeLineList] = useState([])

    // payment detail list
    const [paymentDetail, setPaymentDetail] = useState([])

    //post data to get order
    const loginInfo = LoginInfo('all');

    // table filter
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [commentDesc, setCommentDesc] = useState(null);
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#0a2471' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    // table filter



    const postUserInfo = {
        userRole: loginInfo['role'],
        userId: loginInfo['user_id']
    }

    const allOrderChange = (event) =>{
        setAllOrCheck(event.target.checked)
        localStorage.setItem('allOrderAllow',event.target.checked)
    }


    const [orderId, setOrderId] = useState({})

    const orders = useSelector(state => state.orders.data, shallowEqual)

    const [showLogs, setShowLogs] = useState(false)


    // show payment modal
    const showPaymentModal = (id) => {
        setOrderId(id)
        setPaymentModal(true)
        axios.get(`${api}/payment/index.php?order_id=${id}`)
            .then(res => setPaymentDetail(res.data.data))
    }



    // start order
    const startRequest = (value, labelOrderId) => {
        setOrderId({ order: value, labelOrderId: labelOrderId })
        setStartModal(true)

    }

    const requestToStart = (value) => {
        value = { ...value, deliver_date: value.deliver_date._d, writer_deadline: value.writer_deadline._d, status: 'assign-order', order_id: orderId['order'], labelOrderId: orderId['labelOrderId'], username: loginInfo['username'] }
        axios.post(`${api}/orders/update.php`, JSON.stringify(value))
            .then(res => {
                if (res.status) {
                    message.success(res.data.message)
                    setStartModal(false)
                    if(!monthDataAllow){
                        dispatch(all_data(postUserInfo))
                    }
                  
                }
                else {
                    message.error(res.data.message)
                }
            })

    }


    // current month data

    const searchData = (value) => {
        let { leadsRange } = value;
        let serachPost = { startDate: leadsRange[0]._d, endDate: leadsRange[1]._d, userRole: loginInfo['role'], userId: loginInfo['user_id'] }
        setMonthDataAllow(true)
        dispatch(monthData(serachPost))

    }

    const showCurrentMonth = () => {
        dispatch(all_data(postUserInfo))
        setMonthDataAllow(false)

    }


    // change order status
    const orderStatus = (id, status, to, labelOrderId) => {
        let postData = { id: id, status: status, to: to, username: loginInfo['username'], labelOrderId: labelOrderId }
        updateOrders(postData)
            .then((res) => {
                if (res.status) {
                    message.success(res.message)
                    if(!monthDataAllow){
                        dispatch(all_data(postUserInfo))
                    }
                    
                }
                else {
                    message.error(res.message)

                }
            })
            .catch((e) => message.error('Something went wrong'));
    }

    // assign writer    
    async function gettingWriter() {
        axios.get(`${api}/writers/index.php`)
            .then(res => setWriterList(...[res.data.data]))

    }

    const assignWriterMethod = (value, labelOrderId) => {
        setOrderId({ order: value, labelOrderId: labelOrderId })
        setAssignOutSource(false)
        setAssignWriter(true)
        gettingWriter()
    }

    const assignOutSourceMethod = (value, labelOrderId) => {
        setOrderId({ order: value, labelOrderId: labelOrderId })
        setAssignOutSource(true)
        setAssignWriter(true)
    }



    const assignWriterform = (value) => {
        let writer_name;
        if (value && value.assingwriter) {
            writer_name = writerList.filter(val => val.value === value.assingwriter.toString())
        }
        else {
            writer_name = null;
        }
        value = { ...value, orderId: orderId['order'], labelOrderId: orderId['labelOrderId'], username: loginInfo['username'], writer_name: writer_name && writer_name.length > 0 && writer_name[0].name }

        axios.post(`${api}/orders/update.php`, JSON.stringify(value))
            .then(res => {
                if (res.status) {
                    message.success(res.data.message)
                    setAssignWriter(false)
                    if(!monthDataAllow){
                        dispatch(all_data(postUserInfo))
                    }
                   
                }
                else {
                    message.error(res.data.message)
                }
            })
            .catch(() => message.error('Something went wrong'));

    }

    const showLogsDrawer = (order_id) => {
        setShowLogs(true)
        axios.get(`${api}/orders/logs.php?order_id=${order_id}`)
            .then(res => {
                if (res.status) {
                    setTimeLineList(...[res.data.data])
                }
            })
    }

    const showComment = (comment) =>{
        setCommentDesc(comment)
    }

    // copy order
    const copyOrder = (value) =>{
        copyCompleteOrder(value)
        .then(res => console.log(res))
    }

    // order columns
    const columns = [
        {
            title: 'S.No',
            dataIndex: 'key',
            width: 70,
            fixed: 'left',
            render: (value, item, index) => (index + 1)
        },
        {
            title: 'Order ID',
            dataIndex: 'order_id',
            fixed: 'left',
            width: 150,
            ...getColumnSearchProps('order_id')
        },
        {
            title: 'Client',
            dataIndex: 'customer_name',
            width: 150,
            render: (value) => (<p>{value}</p>)
        },
        {
            title: 'Assign In',
            dataIndex: 'assign_in',
            width: 150,
            ...getColumnSearchProps('assign_in'),
            render: (value, all) => {
                let orderType;
                if (all && all.assign_in.includes('outsource')) {
                    orderType = all.assign_in.split('-')
                    orderType = orderType[0]
                }
                else {
                    orderType = value
                }
                return (
                    <p>{orderType}</p>
                )
            }
        },
        {
            title: 'Writer',
            dataIndex: 'writer_name',
            width: 150,
        },
        {
            title: 'word Count',
            dataIndex: 'word_count',
            width: 150,
        },
        {
            title: 'Client Deadline',
            dataIndex: 'deliver_date',
            width: 150,
            render: (value, { id , amount_currency}) => (value && new Date(value).toDateString())
        },
        {
            title: 'Writer Deadline',
            dataIndex: 'writer_deadline',
            width: 150,
            render: (value) => (value && new Date(value).toDateString())
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 150,
            render: (value, { id , amount_currency}) => (<Button onClick={() => { showPaymentModal(id) }}><span className="order-payment">{amount_currency}</span> {value}</Button>)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 200,
            render: (value) => {
                return (
                    <p className={"status " + value}>{value && value.replace(/-/g, ' ')}</p>
                )
            }
        },
    
        {
            title: 'No Of Refrences',
            dataIndex: 'no_of_refrences',
            width: 150,
        },
        {
            title: 'Paper Topic',
            dataIndex: 'paper_topic',
            width: 150,
        },
        {
            title: 'Lang Style',
            dataIndex: 'lang_style',
            width: 150,
            render : (value) => ( <span className="text-uppercase">{value}</span>)
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            width: 200,
            render : (value) => (<span onClick={()=>showComment(value)} className="comment-btn">Show Comment</span>)
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            fixed: 'right',
            width: 150,
            render: (value, val) => {
                let menu = '';

                if (loginInfo['role'] === 'pcm' || loginInfo['role'] === 'writer') {
                    menu = (
                        <Menu>
                            {loginInfo['role'] === 'pcm' &&
                                <>
                                    {val && val.status !== 'pending' && <Menu.Item onClick={() => orderStatus(value, 'delivered-to-sales', val.agent, val.order_id)} key={1}>Delivered To Sales</Menu.Item>}
                                    {val && val.status !== 'pending' && val.writer && <Menu.Item onClick={() => orderStatus(value, 'revised', val.writer, val.order_id)} key={2}>Revised Order</Menu.Item>}
                                    {val && val.status !== 'pending' && <Menu.Item key={3} onClick={() => assignWriterMethod(value, val.order_id)}>Assign {val && val.assign_in.includes('house') && ' New '} Writer</Menu.Item>}
                                    {val && val.status !== 'pending' && <Menu.Item key={4} onClick={() => assignOutSourceMethod(value, val.order_id)}>Assign {val && val.assign_in.includes('source') && ' New '} OutSource</Menu.Item>}
                                    <Menu.Item onClick={() => showLogsDrawer(value)} key={5}>View History</Menu.Item>
                                    <Menu.Item><Link to={`/orders/` + value }>View Order</Link></Menu.Item>
                                    </>
                            }
                            {
                                loginInfo['role'] === 'writer' &&
                                <>
                                    {/* <Menu.Item onClick={() => orderStatus(value, 'delivered-to-pcm', 'pcm', val.order_id)} key={6}>Delivered To PCM</Menu.Item> */}
                                    <Menu.Item onClick={() => orderStatus(value, 'delivered-to-sales', val.agent, val.order_id)} key={6}>Delivered To Sales</Menu.Item>
                                    <Menu.Item onClick={() => showLogsDrawer(value)} key={7}>View History</Menu.Item>
                                    <Menu.Item><Link to={`/orders/` + value }>View Order</Link></Menu.Item>
                                </>
                            }
                        </Menu>
                    )
                }
                else {
                    menu = (
                        <Menu>
                            <Menu.SubMenu title="Assign">
                                {val && val.status !== 'pending' && <Menu.Item key={15} onClick={() => assignWriterMethod(value, val.order_id)}>Assign {val && val.writer && ' New '} Writer</Menu.Item>}
                                {val && val.status !== 'pending' && <Menu.Item key={16} onClick={() => assignOutSourceMethod(value, val.order_id)}>Assign {val && val.assign_in.includes('source') && ' New '} OutSource</Menu.Item>}
                            </Menu.SubMenu>

                            <Menu.SubMenu title="Mark As">
                                {val && val.status !== 'pending' &&<Menu.Item onClick={() => orderStatus(value, 'revised', 'production', val.order_id)} key={8}>Revised</Menu.Item>}
                                {val && val.status !== 'pending' && <Menu.Item onClick={() => orderStatus(value, 'refunded', 'production', val.order_id)} key={9}>Refunded</Menu.Item>}
                                {val && val.status === 'pending' ? <Menu.Item onClick={() => startRequest(value, val.order_id)} key={10}>Request to Start</Menu.Item> : <Menu.Item onClick={() => orderStatus(value, 'delivered', 'production', val.order_id)} key={4}>Delivered</Menu.Item>}
                            </Menu.SubMenu>

                            <Menu.Item onClick={() => showLogsDrawer(value)} key={11}>View History</Menu.Item>
                            <Menu.Item onClick={() => copyOrder(value)} key={12}>Copy Order</Menu.Item>
                            <Menu.Item><Link to={{
                                pathname: "/generate_order_link/" + value,
                            }} key={13} state={{lead_id: val && val.lead_id}}>Make Payment</Link> </Menu.Item>
                            <Menu.Item>
                                <Link to={{
                                    pathname: "/create_order/",
                                }} key={14} state={{lead_id: val && val.lead_id, order_id: value }}>Edit Order</Link>
                            </Menu.Item>
                            <Menu.Item><Link to={`/orders/` + value }>View Order</Link></Menu.Item>
                        </Menu>
                    )
                }
                return (
                    <StyleDropdown menu={menu} />
                )
            }
        },
    ];

    const columnsToHide = [];
    if(['writer', 'pcm'].includes(loginInfo?.role?.toLowerCase())){
        columnsToHide.push('amount', 'customer_name', 'deliver_date');
    }

    const orderColumn = columns.filter(column => !columnsToHide.includes(column.dataIndex));

    //payment columns
    const paymentColumns = [
        {
            title: 'S.No',
            dataIndex: 'key',
            render: (value, item, index) => (index + 1)
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 100,
            render: (value, row) => (<div><span className="payment-symbol">{row.currency}</span> {value}</div> )
        },
        {
            title: 'Payment Type',
            dataIndex: 'paymentType',
            width: 200,
            render: (value) => (value && value.replace(/-/g, ' '))
        },
        {
            title: 'Time',
            dataIndex: 'time',
            render: (value) => (value && new Date(value).toDateString())
        },
    ];

    useEffect(() => {
        if (!monthDataAllow) {
            dispatch(all_data(postUserInfo))
        }
    }, [dispatch])


    let propsData = {
        columns: orderColumn,
        data: orders && orders.orders ? orders.orders : [],
        pagination: 50,
        height: 350
    }

    return (
        <>
            <div className="layout-content">
                {/* Writer Detail */}
                {/* Month Leads */}
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {
                        orders && orders.monthStats &&
                        <Col
                        key={10}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            xl={6}

                            className="mb-24"
                        >
                            <Card bordered={false} className="criclebox blue-card ">
                                <div className="number ">
                                    <Row align="middle" gutter={[24, 0]}>
                                        <Col xs={18}>
                                            <span className="title">Total Orders</span>
                                            <Title level={3}>
                                                {orders.monthStats.totalOrders} <small>({orders.monthStats.totalCount})</small>
                                            </Title>
                                        </Col>
                                        <Col xs={6}>
                                            <div className="icon-box"><ExportOutlined /></div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    }
                    {
                        orders && orders.monthStats && orders.monthStats.stats.length > 0 && orders.monthStats.stats.map((c, index) => (
                            <Col
                                key={index}
                                xs={24}
                                sm={24}
                                md={12}
                                lg={6}
                                xl={6}

                                className="mb-24"
                            >
                                <Card bordered={false} className={"criclebox  " + c.status}>
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col xs={18}>
                                                <span className="text-capitalize title">{c.status && c.status.replace(/-/g, ' ')}</span>
                                                <Title level={3}>
                                                    {c.orders} <small className={c.status}>({c.count})</small>
                                                </Title>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="icon-box"><ExportOutlined /></div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                </Row>
                {/* Month Leads */}
                {/* Writer Detail */}
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                        <Card bordered={false} className="criclebox cardbody h-full">
                            <div className="project-ant">
                                <div>
                                    <Title level={5}>Order ({orders && orders.monthStats && orders.monthStats.date})</Title>
                                </div>
                                <div className="ant-filtertabs">
                                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra action-head">

                                        {
                                            loginInfo && loginInfo['role'].includes('sale') &&
                                            <Checkbox  onChange={allOrderChange} checked={allOrCheck}>
                                            All Orders
                                    </Checkbox>
                                        }
                                  
                                        <Button onClick={showCurrentMonth}>Current Month Orders</Button>
                                        <Form
                                            name="searchData"
                                            className='lead-form'
                                            initialValues={{}}
                                            onFinish={searchData}
                                        >
                                            <CustomRangeDatePicker name="leadsRange" message="date is required" />
                                            <Button htmlType="submit" type="primary">search</Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            <div className="ant-list-box table-responsive">
                                <div className="position-relative">
                                    {propsData && propsData.data.length > 0 ? <StyleTable {...propsData} /> : <h3 className="no-data-message">No Order To Preview</h3>}

                                </div>

                            </div>
                        </Card>
                    </Col>
                </Row>


                {showLogs &&

                    <StyleDrawer
                        placement='right'
                        onClose={() => setShowLogs(false)}
                        visible={showLogs}
                    >

                        <Col xs={24} sm={24} md={12} lg={24} xl={24} style={{ marginTop: '24px' }}>
                            <Card bordered={false} className="criclebox h-full">
                                {timelineList && timelineList.length > 0 ?
                                    <div className="timeline-box">
                                        <Title level={5} style={{ marginBottom: '20px' }}>Orders History</Title>
                                        <Timeline
                                            className="timelinelist"
                                        >
                                            {timelineList.map((t, index) => (
                                                <Timeline.Item color={t.title} key={index}>
                                                    <Title level={5}>{t.title} by {t.user}</Title>
                                                    <Text>{t.time}</Text>
                                                </Timeline.Item>
                                            ))}
                                        </Timeline>
                                        <Button
                                            type="primary"
                                            className="width-100"
                                            onClick={() => setShowLogs(!showLogs)}
                                        >
                                            {<MenuUnfoldOutlined />} Close Logs
                                        </Button>
                                    </div>
                                    :
                                    <h2>No Logs Exist</h2>
                                }
                            </Card>
                        </Col>
                    </StyleDrawer>}

                {/* request to start modal */}

                {commentDesc !== null && <StyleModal view={commentDesc !== null ? true : false} footer={null} >
                    <CloseCircleFilled className="close-modal" onClick={()=>showComment(null)} />
                    <div className='create-form-inner modal-form'>
                        <h2 className="heading">Order Comment</h2>
                        <p>{commentDesc}</p>
                    </div>
                </StyleModal>
                }
                
                {startModal && <StyleModal view={startModal} footer={null} >
                    <div className='create-form-inner modal-form'>
                        <h2 className="heading">Request To Start</h2>
                        <Form
                            name="orderStartForm"
                            className='brand-form'
                            initialValues={{}}
                            onFinish={requestToStart}
                        >
                            <Row gutter={10}>
                                <Col span={24} className="input-div">
                                    <CustomDatePicker name='writer_deadline' icon={<FormOutlined />} message='Writer Deadline' type="date" />
                                </Col>
                                <Col span={24} className="input-div">
                                    <CustomDatePicker name='deliver_date' icon={<FormOutlined />} message='Client Deadline' type="date" />
                                </Col>
                                <Col span={12} >
                                    <FormButton value="Submit" btntype="submit" />
                                </Col>
                                <Col span={12} >
                                    <FormButton value="Close" btntype="button" onClick={() => setStartModal(false)} />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </StyleModal>
                }

                {/* assign writer modal */}
                {assignWriter && <StyleModal view={assignWriter} footer={null} >
                    <div className='create-form-inner modal-form'>
                        <h2 className="heading">Assign writer</h2>
                        <Form
                            name="assignWriterForm"
                            className='brand-form'
                            initialValues={{}}
                            onFinish={assignWriterform}
                        >
                            <Row gutter={10}>
                                {assignOutSourse ?
                                    <Col span={24} className="input-div">
                                        <InputIcon name="outsource" label="outsource" message="Enter outsource name" />
                                    </Col>
                                    :
                                    <Col span={24} className="input-div">
                                        {writerList && writerList.length > 0 ?
                                            <CustomSelectBox options={writerList} message="select writer" name="assingwriter" />
                                            : 'getting writers name'
                                        }
                                    </Col>
                                }

                                <Col span={12} >
                                    <FormButton value="submit" btntype="submit" />
                                </Col>
                                <Col span={12} >
                                    <FormButton value="Close" btntype="button" onClick={() => setAssignWriter(false)} />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </StyleModal>
                }

                {/* payment modal */}
                {paymentModal && <StyleModal view={paymentModal} footer={null} >
                    <h2 className="heading text-center">Order Payment Info</h2>
                    <div className="payment-type-container">
                    {paymentDetail && paymentDetail.data && 
                    <>
                            <ul className="payment-action-row">
                                <li className="status assign-order"><span>Total</span> <span>{paymentDetail['orderAmount']}</span> </li>
                                <li className="status delivered"><span>Received</span> <span>{paymentDetail['recivedAmount']}</span> </li>
                                <li className="status refunded"><span>{paymentDetail['status']}</span> <span>{paymentDetail['remaining']}</span> </li>
                            </ul>
                    
                        {paymentDetail.data.length > 0 ? <StyleTable columns={paymentColumns} data={paymentDetail.data} /> : <h3 className="no-data-message">No Order To Preview</h3>}
                    </>
                    }
                         

                    </div>
                    <Row align="center">
                        <Col span={12} >
                            <FormButton value="Close" btntype="button" onClick={() => setPaymentModal(false)} />
                        </Col>
                    </Row>
                </StyleModal>
                }





            </div>

        </>
    );
}

export default Orders;
