import { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Button,
    Menu,
    Form,
    message
  } from "antd";
  import { FormOutlined} from '@ant-design/icons';
  import { Link } from "react-router-dom";
  import { PlusCircleOutlined , PaperClipOutlined  } from '@ant-design/icons';

  // component
  import StyleConfirmMessage from "../components/popup/confirmMessage";
  import StyleDropdown from "../components/forms/dropdown";
  import StyleModal from "../components/modal";
  import CustomInputNumber from '../components/forms/inputNumber';
  import FormButton from "../components/forms/button";
  import { useDispatch, useSelector, shallowEqual } from "react-redux";
  import { all_data } from "../store/action/brand";
  import { updateBrand } from "../store/action/brand";
  import CopyToClipboard from "react-copy-to-clipboard";
import { api } from "../api";
import axios from "axios";

  // table code start



 
  
  function Brands() {

    const dispatch = useDispatch()
    const brands = useSelector(state => state.brands.data, shallowEqual)

    const [showNumberModal, setShowNumberModal] = useState(false)
    const [showWhatsappModal, setShowWhatsappModal] = useState(false)

    //brandId

    const [brandId, setBrandId] = useState(null)



    const changePhone = (value) =>{
      value = {...value, id : brandId}
      updateBrand(value)
      .then(res =>{
        dispatch(all_data())
        setShowNumberModal(false)
        message.success(res.message)
      })
    }

    const changeWhatsapp = (value) =>{
      value = {...value, id : brandId}
      updateBrand(value)
      .then(res =>{
        dispatch(all_data())
        setShowWhatsappModal(false)
        message.success(res.message)
      })
    }


    const viewNumberModal = (id) =>{
      setBrandId(id)
      setShowNumberModal(true)
    }
    const viewWhatsappModal = (id) =>{
      setBrandId(id)
      setShowWhatsappModal(true)
    }

    const updateBrandStatus = (id, status) =>{
      let value = {id : id, status : status}
      updateBrand(value)
      .then(res =>{
        dispatch(all_data())
        message.success(res.message)
      })
    }

    const copyLink = () =>{
      message.success('Api has been copied')
    }

    const onPopupConfirm = (id) =>{
      axios.get(`${api}/brand/delete.php?delete_brand=${id}`)
      .then(res =>{
        message.success(res.data.message)
        dispatch(all_data())
      })
      .catch(res => message.error(res.data.message))
    }



    useEffect(()=>{
      dispatch(all_data())
    },[dispatch])



    const columns = [
      {
        title: "Brand Name",
        dataIndex: "domain",
        key: "domain",
        width: "22%",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
      },
    
      {
        title: "Whatsapp",
        key: "whatsapp",
        dataIndex: "whatsapp",
      },
      {
        title: "Code",
        key: "brand_code",
        dataIndex: "brand_code",
      },
      {
        title: "API",
        key: "id",
        dataIndex: "id",
        render : (value, {data}) =>{
          
          
          return(
            <>
                  <CopyToClipboard text={`${api}/brand/index.php?id=${value}`}>
                        <Button onClick={() => copyLink()}><PaperClipOutlined /></Button>
                  </CopyToClipboard>
            </>
            
          )
        }
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render : (value) =>{
          let status = value && parseInt(value) === 1 ? 'active' : 'deactive'
          return(
          <p className={status+'-btn status'}>{status}</p>
          )
          
        }
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        render : (value, {id}) =>{
          const menu = (
            <Menu>
              <Menu.Item key={1}  onClick={()=>updateBrandStatus(id, true)}>Activate</Menu.Item>
              <Menu.Item key={2}  onClick={()=>updateBrandStatus(id, false)}>Deactivate</Menu.Item>
              <Menu.Item key={3} onClick={()=>viewNumberModal(id)}>Change Phone Number</Menu.Item>
              <Menu.Item key={4} onClick={()=>viewWhatsappModal(id)}>Change Whatsapp</Menu.Item>
              <Menu.Item key={5}><StyleConfirmMessage onPopupConfirm={()=>onPopupConfirm(id)} /></Menu.Item>
              
            </Menu>
          );
          return(
            <StyleDropdown menu={menu} />
          )
         
        }
      },
    ];


    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Brands"
                extra={
                  <div className="button-box">
                    <Link to="/add_brand"><Button icon={<PlusCircleOutlined />} className="icon-button">Add Brand</Button></Link> 
                    {/* <Radio.Group onChange={onChange} defaultValue="a">
                      <Radio.Button value="all">All</Radio.Button>
                      <Radio.Button value="active">Active</Radio.Button>
                    </Radio.Group> */}
                  </div>
                }
              >
                <div className="table-responsive">
                  {brands && brands.length > 0 &&
                  <Table
                    columns={columns}
                    dataSource={brands}
                    pagination={false}
                    className="ant-border-space"
                  />
                  }
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        {showNumberModal && 
        <StyleModal view={showNumberModal}  footer={null}>
            <div className='create-form-inner modal-form'>
            <h2 className="heading">Change Phone Number</h2>
                <Form
                    name="phoneNumberChange"
                    className='brand-form'
                    initialValues={{}}
                    onFinish={changePhone}
                >
                     <Row gutter={{ xs: 8, sm: 16, md: 10, lg: 10 }} align="center">
                        <Col span={24} className="input-div gutter-row">
                            <CustomInputNumber name='phone' icon={<FormOutlined />} message='Phone Number' type="string" min={11} />
                        </Col>
                        <Col span={10} md={12} className="gutter-row">
                          <FormButton value="Change" btntype="submit" />
                        </Col>       
                        <Col span={10} md={12} className="gutter-row" >
                            <FormButton value="Close" btntype="button"  onClick={()=>setShowNumberModal(false)} />
                        </Col>    
                    </Row>      
                </Form>
                </div>
        </StyleModal  >
        }
        {
        showWhatsappModal && <StyleModal view={showWhatsappModal}  footer={null} >
          <div className='create-form-inner modal-form'>
            <h2 className="heading">Change Whatsapp Number</h2>
                <Form
                    name="phoneNumberChange"
                    className='brand-form'
                    initialValues={{}}
                    onFinish={changeWhatsapp}
                >
                     <Row gutter={10}>
                        <Col span={24} className="input-div">
                            <CustomInputNumber name='whatsapp' icon={<FormOutlined />} message='Whatsapp' type="string" min={11} />
                        </Col>
                        <Col span={12} >
                          <FormButton value="Change" btntype="submit" />
                        </Col>       
                        <Col span={12} >
                            <FormButton value="Close" btntype="button"  onClick={()=>setShowWhatsappModal(false)} />
                        </Col>    
                    </Row>      
                </Form>
                </div>
        </StyleModal>
        }
      </>
    );
  }
  
  export default Brands;
  