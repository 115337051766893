import { Form } from 'antd';
import { Col, Row } from 'antd';
import { useState } from 'react';

//component
import InputIcon from '../forms/inputIcon';
import FormButton from '../forms/button';
import CustomInputNumber from '../forms/inputNumber';
import { useNavigate } from 'react-router-dom';
import AppLoader from '../loader'
import axios from 'axios';
import { api } from '../../api';
import { message } from 'antd';




export default function AddBrandForm() {
    const Navigate = useNavigate();
    const [form] = Form.useForm();
    const [blockSubmit, setBlockSubmit] = useState(false)

    // loginSubmit
    const onSubmit = async (values) => {
        setBlockSubmit(true)
        axios.post(`${api}/brand/index.php`, JSON.stringify(values))
        .then((res)=>{
            if(res.data.status){
                message.success(res && res.data.message)
                setTimeout(() => {
                    form.resetFields();
                    Navigate('/brands')
                }, 1500);
              
            }
            else{
                setBlockSubmit(false)
                message.error(res && res.data.message)
            }
        })
    };



    return (
        <div className='create-form-inner'>
            <h2>Add New Brand</h2>
            {blockSubmit && <AppLoader size="small" />}
                <Form
                    form={form}
                    name="login"
                    className='brand-form'
                    initialValues={{}}
                    onFinish={onSubmit}
                >
                     <Row>
                        <Col span={24} md={12}  className="input-div">
                            <InputIcon name='domain' message='Domain Name (eg : asignmenthelp.com)' type="string" />     
                        </Col> 
                        <Col span={24} md={12} className="input-div">
                            <InputIcon name='brand_name' message='Website Name (eg : asignmenthelp)' type="string" />     
                        </Col>       
                    </Row>
                    <Row>
                        <Col span={24} md={12} className="input-div ">
                            <CustomInputNumber name='phone'  message='Phone Number' type="string" min={11} />      
                        </Col>
                        <Col span={24} md={12} className="input-div">
                            <CustomInputNumber name='whatsapp'  message='Whatsapp' type="string" min={11} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} md={12} className="input-div ">
                            <InputIcon name='brand_code'  message='Brand Code' type="string" />
                        </Col>
                        <Col span={24} md={12} className="input-div">
                            <InputIcon name='team' message='team eg:(sales)' type="string" />
                        </Col>
                    </Row>
                    <Row justify='center'>
                        <Col span={8} className="input-div ">
                            <FormButton value="Add Brand" btntype="submit"  disabled={blockSubmit} />
                        </Col>
                    </Row>
                    
                </Form>
                </div>
    );
};

